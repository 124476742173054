/*
Template Name: Shreyu - Responsive Bootstrap 5 Admin Dashboard
Author: CoderThemes
Version: 2.0.0
Website: https://coderthemes.com/
Contact: support@coderthemes.com
File: Main Css File
*/


//Fonts
@import "custom/fonts/fonts";

//Core files
@import "../../../node_modules/bootstrap/scss/functions";
@import "../../../node_modules/bootstrap/scss/variables";
@import "config/variables";
@import "../../../node_modules/bootstrap/scss/mixins.scss";


// Structure
@import "custom/structure/general";
@import "custom/structure/left-menu";
@import "custom/structure/topbar";
@import "custom/structure/page-head";
@import "custom/structure/footer";
@import "custom/structure/right-sidebar";
@import "custom/structure/layouts";
@import "custom/structure/horizontal-nav";
@import "custom/structure/two-column-menu";

//Components
@import "custom/components/avatar";
@import "custom/components/custom-checkbox";
@import "custom/components/custom-radio";
@import "custom/components/helper";
@import "custom/components/social";
@import "custom/components/widgets";
@import "custom/components/print";
@import "custom/components/preloader";

//Pages
@import "custom/pages/authentication";
@import "custom/pages/components-demo";
@import "custom/pages/error";
@import "custom/pages/email";
@import "custom/pages/pricing";
@import "custom/pages/profile";
@import "custom/pages/task";
@import "custom/pages/timeline";
@import "custom/pages/kanbanboard";
@import "custom/pages/landing";
@import "custom/pages/file-manager.scss";
@import "custom/pages/chat";


// Plugins
@import "custom/plugins/apexcharts";
@import "custom/plugins/calendar";
@import "custom/plugins/colorpicker";
@import "custom/plugins/sparkline-chart";
@import "custom/plugins/datatable";
@import "custom/plugins/datepicker"; // react-datepicker style
@import "custom/plugins/form-wizard";
@import "custom/plugins/select2";
@import "custom/plugins/simplebar";
@import "custom/plugins/multiple-select";
@import "custom/plugins/bootstrap-touchspin";
@import "custom/plugins/parsley";
@import "custom/plugins/quilljs-editor";
@import "custom/plugins/react-select"; // react-select style
@import 'custom/plugins/react-table'; // react table style
@import "custom/plugins/flatpickr"; 
@import "custom/plugins/dropzone";
@import "custom/plugins/google-maps";
@import "custom/plugins/vector-maps";

.container {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.column {
  height: max-content;
  min-height: 100px;
  width: 160px;
  margin: 10px;
  border-radius: 10px;
  box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
  border: 2px solid #7d7d7d; /* Параметры границы */
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.do-it-column {
  background-color: #fff0f0;
}

.in-progress-column {
  background-color: #fef2e7;
}

.awaiting-review-column {
  background-color: #fffada;
}

.done-column {
  background-color: #f5ffe5;
}

.movable-item {
  border-radius: 5px;
  background-color: #fafdff;
  width: 140px;
  margin: 10px auto;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0px 0px 3px rgba(0, 0, 0, 0.5);
}
